import React from 'react'
import classNames from 'classnames'
import { maplibre, extractFeature, MAPTILER_TOKEN } from '../../utils/maps'
import { useAgency } from '../../hooks/use-agency'
import { getThemeColor } from '../../theming/helpers'
import center from '@turf/center'
import bbox from '@turf/bbox'
import { NegotiationToolbar } from './negotiation-toolbar'
import { FormattedMessage } from 'react-intl'
import H2 from '../../theming/H2'

class PropertiesMap extends React.Component {
  constructor(props) {
    super(props)
    this.propertiesGeoJSON = {
      type: 'FeatureCollection',
      features: this.props.properties.map(extractFeature).filter(Boolean),
    }

    if (this.propertiesGeoJSON.features.length > 0) {
      const point = center(this.propertiesGeoJSON)
      this.state = {
        lng: point.geometry.coordinates[0],
        lat: point.geometry.coordinates[1],
        zoom: 4,
      }
    } else {
      this.state = {
        lat: 46.7629421,
        lng: 4.2452205,
        zoom: 4,
      }
    }
    this.themeColor = getThemeColor(props.agencySettings)
  }

  componentDidMount() {
    this.map = new maplibre.Map({
      container: this.mapContainer,
      style: `https://api.maptiler.com/maps/streets-v2/style.json?key=${MAPTILER_TOKEN}`,
      center: [this.state.lng, this.state.lat],
      zoom: this.state.zoom,
      fadeDuration: 0,
    })

    this.map.on('load', this.showMarkers)
  }

  centerOnProperties = () => {
    if (this.propertiesGeoJSON.features.length > 0) {
      const bounds = bbox(this.propertiesGeoJSON)
      this.map.fitBounds(bounds, {
        maxZoom: 8,
      })
    }
  }

  showMarkers = () => {
    this.map.addSource('properties', {
      type: 'geojson',
      data: this.propertiesGeoJSON,
      cluster: true,
    })

    this.map.addLayer({
      id: 'properties-clusters-circle',
      type: 'circle',
      source: 'properties',
      filter: ['has', 'point_count'],
      paint: {
        // get the icon name from the source's "icon" property
        // concatenate the name to get an icon from the style's sprite sheet
        'circle-radius': 14,
        'circle-color': this.themeColor,
        'circle-stroke-color': '#ffffff',
        'circle-stroke-width': 3,
      },
    })

    this.map.addLayer({
      id: 'properties',
      type: 'circle',
      source: 'properties',
      filter: ['!', ['has', 'point_count']],
      paint: {
        // get the icon name from the source's "icon" property
        // concatenate the name to get an icon from the style's sprite sheet
        'circle-radius': 8,
        'circle-color': this.themeColor,
      },
    })

    this.map.addLayer({
      id: 'properties-clusters-label',
      type: 'symbol',
      source: 'properties',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['Arial Unicode MS Bold'],
        'text-size': 12,
        'symbol-z-order': 'source',
      },
      paint: {
        'text-color': 'white',
      },
    })
    this.centerOnProperties()
  }

  render() {
    return (
      <div
        id="homePropertiesMap"
        ref={(el) => (this.mapContainer = el)}
        style={{
          height: '100%',
          width: '100%',
          position: 'absolute',
          overflow: 'hidden',
        }}
      />
    )
  }
}

export default ({ properties }) => {
  const { settings } = useAgency()

  return (
    <div className="c-row c-row--beta">
      <div className="o-container">
        <div className="o-grid">
          <div className="o-grid__item">
            <H2 className="section-title">
              <FormattedMessage id="properties" />
            </H2>
          </div>
          <div className="o-grid__item">
            <div
              style={{ width: '100%', height: '400px', position: 'relative' }}
            >
              <PropertiesMap
                properties={properties}
                agencySettings={settings}
              />
              <div
                className="c-row c-row--collapsed c-row--overlay"
                style={{ position: 'absolute', width: '100%' }}
              >
                <section className="o-grid o-grid--gutter">
                  <div
                    className={classNames('o-grid__item', {
                      'o-grid__item u-3-of-5-bp3 u-push-1-of-5-bp3':
                        settings.transaction_types === 'let_and_sale',
                      'u-2-of-6-bp3 u-push-2-of-6-bp3':
                        settings.transaction_types !== 'let_and_sale',
                    })}
                  >
                    <NegotiationToolbar />
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
